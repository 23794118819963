* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-family: Roboto;
  color: rgba(0, 0, 0, 0.84);
}

.App {

}
