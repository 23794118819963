.Header {
  height: 64px;
  background-color: #ffffff;
  display: flex;

  .HeaderContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .HeaderLogo {
    display: flex;
    align-items: center;

    &-image {
    }

    &-name {
      font-family: "Manrope", sans-serif;
      margin-left: 8px;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.5);
      user-select: none;
    }
  }
}
